<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="clickEvent">
            <el-tab-pane label="售票统计" name="1" :link="`/manage/activity/edit/statistics/${id}`"></el-tab-pane>            
            <el-tab-pane label="购票用户" name="2" :link="`/manage/activity/edit/statistics/userindex/${id}`"></el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    props:{
        name:{
            type: String,
            default: '1'
        }
    },
    data(){
        return{
            activeName: this.name,
			id: this.$route.params.id
        }
    },
    methods:{
        clickEvent(tab){
            if (tab.$attrs.link) this.$router.push(tab.$attrs.link)
        }
    }
}
</script>

<style lang="scss" scoped>
.el-tabs{
    margin:-10px 0 10px 0;
}
</style>