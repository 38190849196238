<template>
    <div v-loading="dataLoading">
		<div class="page-top-box">
			<div class="page-title-box">
				<div class="go-back">
					<el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
				</div>
				售票统计
			</div>
		</div>
		<tab name="2"></tab>
        <el-card class="box-card" v-if="data && data.provinceList">
            <div slot="header" class="clearfix">
                <span>
                    <span class="el-icon-data-line"></span> 地区分布 <span class="color-danger">（按购票时用户所在地进行统计，所在地通过购票用户授权的IP、GPS信息计算得出，用户未授权或其他原因导致无法统计的会被统一归为【未知】）</span>
                </span>
            </div>
        	<div class="page-list-box">				
        	    <el-table :data="data.provinceList.slice((currentPage - 1) * pagesize, currentPage * pagesize)"  border style="width: 100%"  size="small" stripe>
        	        <el-table-column prop="name" label="省份"></el-table-column>               
        	        <el-table-column prop="num" label="人数"></el-table-column>
        	        <el-table-column prop="ratio" label="占比"></el-table-column>
        	    </el-table>
				<div v-if="data && data.provinceList" class="page-pagination-box MT20">
					<el-pagination
						  @size-change="handleSizeChange"
						  @current-change="handleCurrentChange"
						  :current-page="currentPage"
						  :page-sizes="[5, 10, 15, 20]"
						  :page-size="pagesize"
						  layout="total, sizes, prev, pager, next, jumper"
						  :total="data.provinceList.length">
					</el-pagination>
				</div>
        	</div>
			<div class="page-list-box">
			    <el-table :data="data.cityList.slice((currentPage2 - 1) * pagesize2, currentPage2 * pagesize2 )"  border style="width: 100%"  size="small" stripe>
			        <el-table-column prop="name" label="城市"></el-table-column>               
			        <el-table-column prop="num" label="人数"></el-table-column>
			        <el-table-column prop="ratio" label="占比"></el-table-column>
			    </el-table>
				<div v-if="data && data.cityList" class="page-pagination-box MT20">
					<el-pagination
						  @size-change="handleSizeChange2"
						  @current-change="handleCurrentChange2"
						  :current-page="currentPage2"
						  :page-sizes="[5, 10, 15, 20]"
						  :page-size="pagesize2"
						  layout="total, sizes, prev, pager, next, jumper"
						  :total="data.cityList.length">
					</el-pagination>
				</div>
			</div> 			
        </el-card>
		<el-card class="box-card MT20" v-if="data && data.ageList">
			<div slot="header" class="clearfix">
			    <span>
			        <span class="el-icon-data-line"></span> 年龄段分布 
			    </span>
			</div>
			<div id="ageChart"></div>
		</el-card>
		
		<el-card class="box-card MT20" v-if="data && data.sexRatio">
			<div slot="header" class="clearfix">
			    <span>
			        <span class="el-icon-data-line"></span> 性别分布 
			    </span>
			</div>
			<div id="sexChart"></div>
		</el-card>
        
    </div>
</template>

<script>
import { Chart } from '@antv/g2';
import Tab from './components/tab'
export default {
    props: {
        activityId: {
            type: [Number, String],
            default: () => null
        },
        haveEditAudit: {
            type: Boolean,
            default: false
        }
    },
    components: {Tab},
    data() {
        return {
            dataLoading: false,
			id: this.$route.params.id,
            ticketPrices: null,
			data:null,
			chart1: null,
			chart2: null,
			chart3: null,
			currentPage: 1, //初始页
			pagesize: 10, // 每页的数据
			currentPage2: 1, //初始页
			pagesize2: 10, // 每页的数据
        }
    },
    mounted() {
        this.getDatalist()
    },
    methods: {
        //获取列表
        getDatalist() {
            this.dataLoading = true;
            this.$request({
                url: "/activitySaleStatistice/userPortrait",
                params: { activityId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.data = result
						this.dataLoading = false;
						if( this.data.ageList && this.data.ageList.length > 0 ){
							this.$nextTick(() => {
								this.drawChart();								
							})
						}
						
						if( this.data.sexRatio && this.data.sexRatio.length > 0 ){
							this.$nextTick(() => {
								this.drawChart2();								
							})
						}
						
                    } else {
                        self.$message.error(msg || "获取数据失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取数据失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
		handleSizeChange (size) {
			this.pagesize = size;
		},
		handleCurrentChange (currentPage) {
			this.currentPage = currentPage;
		},
		handleSizeChange2 (size2) {
		    this.pagesize2 = size2;
		},
		handleCurrentChange2 (currentPage2) {			
		    this.currentPage2 = currentPage2;
		},
		//年龄
		drawChart(){
		    if( this.chart1 ) this.chart1.destroy();
		    
			let _data = [];
			_data = [...this.data.ageList];			
			
		    _data.map( v => {
		        v.num = parseFloat(v.num)
		    })
			
		    this.chart1 = new Chart({
		        container:'ageChart',
		        autoFit: true,
		        height: 200,
		    });
		    this.chart1.data(_data);
		    this.chart1.interaction('active-region'); 
		   
			this.chart1.tooltip({
			        showTitle: false,//不显示标题
			        showMarkers: false,//不显示贴敷上去的小点
			        position: "right",//显示位置			       
			        itemTpl: `<div  style="margin-bottom: 10px;">
			        <span style="background-color:{color};" class="g2-tooltip-marker"></span>
			        <span style="margin-right:10px"> {name}，人数：{num}，占比：{ratio}</span>
			        </div>
			        `,
			      });			 
			   this.chart1.interval().position("name*num").tooltip("name*num*ratio", function (name, num ,ratio) {
			          return { name, num ,ratio};
			    });
			this.chart1.render();
		},
			
		//性别
		drawChart2(){
		    if( this.chart3 ) this.chart3.destroy();
			
		    let _data = [];
		    _data = [...this.data.sexRatio];
			_data.map( v => {
			    v.percent = v.num
			})
			_data = _data.filter( v => v.num > 0 );
			
			
		    this.chart3 = new Chart({
		        container: 'sexChart',
		        autoFit: true,
		        height: 420,				
		    });
			this.chart3.coordinate('theta', {
			    radius: 0.6,
			});
			
		    this.chart3.data(_data);
			this.chart3.scale('percent', {
			    formatter: (val) => {
			        val = (val * 100).toFixed(2) + '%';
			        return val;
			    },
			});
			
			this.chart3.tooltip({
			    showTitle: false,
			    showMarkers: false,
			});
			
			this.chart3.legend('name', {
			    position: 'top',
			});
			this.chart3
			    .interval()
			    .adjust('stack')
			    .position('percent')
			    .color('name')
			    .label('percent', (val) => {
			        return {
			            // offset: val > 0.05 ? -30 : null,
			            content: (obj) => {
			                return obj.name + '：（人数：' + obj.num + ' 占比：' + obj.ratio + '）';s
			            },
			        };
			    })
			    .tooltip('name*num*ratio', (name, num, ratio) => {
			      
			        return {
			            name: name,
						num:num,
			            value: num,
			        };
			    });
			
			
			this.chart3.render();
		},
		//城市
       
    }
}
</script>